import React, { useEffect, useState } from 'react'
import service from '../service/dbService'
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { plazaServices } from './tollPlaza/plazaReportService';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
export const ChargeTrends = () => {
    const [thingList, setThingList] = useState([])
    const [selectedThing, setSelectedThing] = useState();
    const [masterData,setMasterData] = useState({})
    const [capacityLine,setCapcityLine] = useState()
    const [efficenicyLine,setEfficenicyLine] = useState()
    const serviceId = localStorage.getItem("serviceId");
    const LineChart = (type, data, yAxisHeader,desc,key,header) => {
        let seriesData = [];
        let xAxisData = [];
        data.map((item) => {
            seriesData.push(Number(item[`${key}`]));
            xAxisData.push(item.cyc_count);
            
        });
        const lineChart = {
            chart: {
                type: `${type}`
            },
            title: {
                text: header,
                align: 'center'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                categories: xAxisData,
                accessibility: {
                    rangeDescription: ''
                },
                title: {
                    text: `${desc ? desc :""}`
                }
            },
            yAxis: {
                title: {
                    text: `${yAxisHeader}`
                }
            },
            legend: {
                enabled: false 
            },
            showInLegend: false,
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                }
            },

            series: [{
                name: yAxisHeader,
                data: seriesData
            }],
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['viewFullscreen', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
                    }
                }
            }
        };
        return lineChart;
    };
    const dataGatherer = async(ThingName)=>{
        let matchObj = {};
        if(ThingName.value)matchObj["thingName"] = ThingName.value;
        else matchObj["thingName"] = ThingName;
        const payload = {
            filter:matchObj,
            projection:{

            }
        }
        Promise.all([plazaServices.general(serviceId,payload,"nq-cycle-count")]).then((res)=>{
            setMasterData(res[0])
            let CapacityLine = LineChart('line', res[0], "Effective Capacity","Number of Cycles","effective_capacity","Change in Effective AH with age (cycles)");
            let EfficiencyLine = LineChart('line',res[0],"Efficiency","Number of Cycles","charging_efficiency","Change in Charging efficiency with age")
            setCapcityLine(CapacityLine);
            setEfficenicyLine(EfficiencyLine)
        })
    }
    const populate = async () => {
        let projection = { thingName: 1, "location.siteName": 1 }
        let res = await service.getThingNames(serviceId, projection);
        let tempArray = [];
        res.map((item) => {
            let temp = {
                label: item.location.siteName,
                value: item.thingName
            }
            tempArray.push(temp);
        })
        setThingList(tempArray)
        setSelectedThing(tempArray[0].value)
        dataGatherer(tempArray[0])
    }
    useEffect(() => {
        populate();
    }, [])

    return (
        <div className='col-12 grid justify-content-center ml-1 lg:ml-0'>
            <div className='col-12 grid lg:mr-4 mr-0'>
                <div className='lg:col-3 col-12'><Dropdown
                    id="tollDropdown"
                    value={selectedThing}
                    options={thingList}
                    onChange={(e) => {
                        setSelectedThing(e.value)
                        dataGatherer(e.value)
                    }}
                    placeholder={
                        <span className="flex align-items-center" style={{ color: '#0C6291' }}>
                            <i className="" style={{ marginRight: '8px' }}></i>
                            Select a Site
                        </span>
                    }

                    className="p-dropdown flex align-items-center bg-white"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291',
                        width: "100%"
                    }}
                /></div>
            </div>
            <div className='col-12 flex flex-wrap  justify-content-around  card  lg:ml-0 borderStyle3' style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <h3 className='col-12 flex justify-content-center align-items-center font-bold text-2xl text-gray-800 text-uppercase mb-3'>Change in effective capacity and charging efficiency with age</h3>
                <div className='lg:col-4 col-12   '>
                    <DataTable value={masterData} selectionMode='single' rows={10} style={{ marginTop: "0" }} scrollable className='mt-1' onRowSelect={(row) => { }}>
                        <Column headerClassName='bg-cyan-500 text-white font-medium' header="Cycle Count" bodyClassName='bg-white font-medium  surface-300 bg-gray-200' field="cyc_count"></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Effective Capacity" field="effective_capacity" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 col-1' ></Column>
                        <Column headerClassName='bg-cyan-500 text-white' header="Charging Efficiency" field="charging_efficiency" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 col-1' ></Column>
                    </DataTable>
                </div>
                <div className='lg:col-8 col-12'>
                    {<HighchartsReact highcharts={Highcharts} options={capacityLine} />}
                </div>
                <div className=' col-12'>
                    {<HighchartsReact highcharts={Highcharts} options={efficenicyLine} />}
                </div>
            </div>

        </div>
    )
}
