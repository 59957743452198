import React, { useEffect, useState } from 'react'
import { plazaServices } from './tollPlaza/plazaReportService'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import service from './imagineHome.js/imagineHomeService';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';

export const Customer = () => {
    const [customerList, setCustomerList] = useState([])
    const [selectedCustomerList, setSelectedCustomerList] = useState([])
    const [designerList, setDesignerList] = useState([]);
    const [assignedDesigner, setAssignedDesigner] = useState()
    const [isAdmin, setAdmin] = useState(false);
    const serviceId = localStorage.getItem("serviceId");
    const [error, setError] = useState('')
    let jwtToken = localStorage.getItem("token")

    let customerFill = (res) => {
        let tempArray = [];
        let customers = {}
        for (let i = 0; i < res[0].length; i++) {
            if (tempArray.includes(res[0][i].customerID)) {
                customers[`${res[0][i].customerID}`].ProjectName = [...customers[`${res[0][i].customerID}`].ProjectName, res[0][i].ProjectName];
            }
            else {
                res[0][i].ProjectName = [res[0][i].ProjectName]
                tempArray.push(res[0][i].customerID)
                customers[`${res[0][i].customerID}`] = res[0][i];
            }
        }
        let tempCustomer = [];
        for (const key in customers) {
            if (!customers[key].designerID) tempCustomer = [customers[key], ...tempCustomer]
            else tempCustomer.push(customers[key])
        }
        setCustomerList(tempCustomer)

    }

    useEffect(() => {
        const payload = {
            filter: {},
            projection: {
                customerID: 1,
                ProjectName: 1,
                designerID: 1
            }
        }
        const designersPayload = {
            filter: {
                "user.role": "ihdesigner",
                serviceId: serviceId
            },
            projection: {
                "user": 1,
                "_id": 0
            }
        }
        Promise.all([plazaServices.general(serviceId, payload, "nq-customer-project-designer-mappings"), service.fetchUserDetails(designersPayload)]).then((res) => {
            let tempDesigner = [];
            customerFill(res);
            res[1].map((item) => {
                let temp = {
                    label: item.user.name,
                    value: item.user.email
                }
                tempDesigner.push(temp);
            })
            setDesignerList(tempDesigner)
        })
    }, [])

    useEffect(() => {
        if (!selectedCustomerList.length) setAssignedDesigner(null)
    }, [selectedCustomerList])

    useEffect(() => {
        let userpermissions = localStorage.getItem("permissions");
        userpermissions = JSON.parse(userpermissions);
        if (userpermissions.includes("UserManagement.READ") && userpermissions.includes("UserManagement.WRITE")) setAdmin(true)
    }, [])

    const populate = async () => {
        const payload = {
            filter: {},
            projection: {
                customerID: 1,
                ProjectName: 1,
                designerID: 1
            }
        }
        Promise.all([plazaServices.general(serviceId, payload, "nq-customer-project-designer-mappings")]).then((res) => {
            customerFill(res)
        })

    }

    const removeMsg = () => {
        setTimeout(() => {
            setError("")
            setSelectedCustomerList([])
            setAssignedDesigner(null)
            populate()
        }, 2000)
    }

    const assignDesignerUtility = (row) => {
        const changeHandler = () => {
            if (selectedCustomerList.includes(row.customerID)) {
                let temp = [];
                selectedCustomerList.map((item) => {
                    if (item !== row.customerID) temp.push(item)
                })
                setSelectedCustomerList(temp);
                return;
            }
            setSelectedCustomerList([...selectedCustomerList, row.customerID])

        }
        return <Checkbox onChange={changeHandler} checked={selectedCustomerList.includes(row.customerID)}></Checkbox>
    }

    const projectShow = (row) => {
        const projectNames = row.ProjectName.join(', ');
        return (
            <>
                <span>{projectNames}</span>
                <br />
            </>
        );
    };

    const onSubmit = async () => {
        let header = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwtToken,
            serviceId: serviceId
        };
        const payload = {
            "custIds": selectedCustomerList,
            "designerId": assignedDesigner,
            "serviceId": serviceId
        }
        let res = await axios({
            method: "post",
            url: `${process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL}/assign-cust-to-designer`,
            data: payload,
            headers: header,
        });
        setError(res.data.message)
        if (res.data.responseCode === 200) removeMsg();
        else setTimeout(() => {
            setError("")
        }, 3000)
    }

    return (
        isAdmin ? <>
            <h4 className='mt-1 mb-3 ml-1 lg:ml-0 font-larken'>Assign Designer</h4>
            <div className='col-12 grid justify-content-center card ml-1 lg:ml-0'>
                {selectedCustomerList.length ? <div className='col-12 grid justify-content-end align-items-center gap-2'>
                    <p className='mt-4 text-l mr-1 col-12 lg:col-2 font-Gerstner-ProgramRegular' style={{ color: "#C6A600" }}>{error.length ? error : ""}</p>
                    <Dropdown
                        id="tollDropdown"
                        value={assignedDesigner}
                        options={designerList}
                        onChange={(e) => setAssignedDesigner(e.value)}
                        placeholder={
                            <span className="flex align-items-center font-Gerstner-ProgramRegular" style={{ color: '#36454F' }}>
                                <i className="font-Gerstner-ProgramRegular" style={{ marginRight: '8px' }}></i>
                                Select a Designer
                            </span>
                        }

                        className="p-dropdown flex align-items-center bg-white  "
                        style={{
                            borderRadius: '20px',
                            border: '2px solid #36454F',
                            color: "#36454F"
                        }}
                    />
                    <Button label="Submit" className='ml-2 font-Gerstner-ProgramRegular' severity="Info" onClick={onSubmit} style={{ backgroundColor: "#C6A600", color: "#FFFFF0" }} />

                </div> : null}
                <div className='col-12 '>
                    <DataTable value={customerList} selectionMode='single' rows={10} style={{ marginTop: "0" }} scrollable className='mt-1' >
                        <Column body={assignDesignerUtility} headerClassName=' text-black font-medium font-larken' header="" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 font-Gerstner-ProgramRegular' style={{ maxWidth: "40px", minHeight: "55px", backgroundColor: '#E6E6E6', color: "#36454F" }} ></Column>
                        <Column headerClassName=' text-black font-medium font-larken text-xl m-0' header="Customer Id" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 font-Gerstner-ProgramRegular' field="customerID" style={{ minWidth: "320px", backgroundColor: '#E6E6E6', color: "#36454F" }}></Column>
                        {/*<Column headerClassName='bg-cyan-500 text-white' header="Project" body={projectShow} bodyClassName='bg-white font-medium  surface-300 bg-gray-200 col-1' ></Column>*/}
                        <Column headerClassName='text-black font-larken text-xl m-0' header="Designer" field="designerID" bodyClassName='bg-white font-medium  surface-300 bg-gray-200 col-1 font-Gerstner-ProgramRegular' style={{ backgroundColor: '#E6E6E6', color: "#36454F" }}></Column>
                    </DataTable>
                </div>
            </div>
        </> : <div className="col-12 " style={{ color: "#01579B" }}>
            <div className="card flex flex-column align-items-center justify-content-center" style={{ height: "60vh", backgroundColor: "#B3E5FC" }}>
                <span className="fa-solid fa-circle-info" style={{ fontSize: "3rem" }}></span>
                <h3 className='font-larken'>You are not authorized to access this page</h3>
            </div>
        </div>
    )
}
