import React, { useEffect, useRef, useState } from 'react'
import imagineHomeService from "./imagineHomeService"
import { Dropdown } from 'primereact/dropdown'
import { TabView } from 'primereact/tabview'
import { TabPanel } from 'primereact/tabview'
import ListFiles from './ListFiles'
import { Dialog } from 'primereact/dialog'
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button'
import { Toast } from "primereact/toast";

const DesignerHome = () => {
    const serviceId = localStorage.getItem("serviceId")
    const [selectedCustomer, setSelectedCustomer] = useState("")
    const [selectedProject, setSelectedProject] = useState("")
    const [errMsg, setErrMsg] = useState("")
    const [selectedFileType, setSelectedFileType] = useState("")
    const [activeIndex, setActiveIndex] = useState(0)
    const [loader, setLoader] = useState(true)
    const customersList = useRef([])
    const projectsList = useRef([])
    const oneProjectFiles = useRef([])
    const room_file_type_master = useRef({})
    const [roomWiseFiles, setRoomWiseFiles] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [file, setFile] = useState(null);
    const [disabled, setDisabled] = useState(false)
    const roomName = useRef("")
    const toast = useRef();


    const upload_down_map = {
        0: "Customer",
        1: "Designer"
    }
    const ddStyle = {
        borderRadius: '20px',
        border: '2px solid #9AAB89',
        width: "100%",
        backgroundColor: "#FBF9F3",
        color : "#1D1B1C",
        fontFamily : "Gerstner-ProgramRegular"
    }
    const user = localStorage.getItem("email")
    useEffect(() => {
        const userDetailsPayload = {
            filter: {
                "$or": [
                    { "user.mobile": user },
                    { "user.email": user }
                ],
                "serviceId": serviceId
            },
            projection: {
                "user": 1,
                "_id": 0
            }
        }

        imagineHomeService.fetchUserDetails(userDetailsPayload).then(async (res) => {
            if (res.length && res[0].user) {
                const fACpayload = {
                    operation: "distinct",
                    key: "customerID",
                    query: { "designerID": res[0].user.email }
                }
                const customers = await imagineHomeService.fetchDistinctDetails(fACpayload, serviceId)
                if (customers.length) {
                    let firstCustomer = customers[0]
                    const fCustProjpayload = {
                        operation: "distinct",
                        key: "ProjectName",
                        query: { "customerID": firstCustomer }
                    }
                    const projects = await imagineHomeService.fetchDistinctDetails(fCustProjpayload, serviceId)
                    if (projects.length > 0) {
                        let firstProject = projects[0]
                        customersList.current = customers.map(item => ({ name: item, value: item }))
                        projectsList.current = projects.map(item => ({ name: item, value: item }))
                        setSelectedCustomer(firstCustomer)
                        setSelectedProject(firstProject)
                    }
                }
            }
            setLoader(false)
        })
    }, [])
    useEffect(() => {
        if (selectedCustomer !== "" && selectedProject !== "") {
            const formData = new FormData()

            formData.append("serviceId", serviceId)
            formData.append("customerID", selectedCustomer)
            formData.append("ProjectName", selectedProject)
            formData.append("isProjectActive", true)

            imagineHomeService.fetchProjectFiles(formData).then((res) => {
                if (res && res.files && res.files.length > 0) {
                    let roomWiseFilesResp = roomWiseFilterFiles(res.files)
                    setRoomWiseFiles(roomWiseFilesResp)
                    oneProjectFiles.current = res.files
                }
                else {
                    setRoomWiseFiles([])
                }
            })
        }

    }, [selectedCustomer, selectedProject])
    const roomWiseFilterFiles = (files) => {
        let roomLevel = 3
        const customerFiles = []
        const designerFiles = []
        const custSet = new Set()
        const designSet = new Set()
        files.forEach((item, i) => {
            const splittedPath = item.split("/")
            const room = splittedPath[roomLevel]
            const file = splittedPath[splittedPath.length - 1]
            if (room) {
                if (splittedPath[roomLevel + 1] === upload_down_map[0]) {
                    custSet.add(splittedPath[roomLevel - 1] + "_" + room)
                    customerFiles.push({ filename: file, room: room, roomType: splittedPath[roomLevel - 1], filekey: item })
                }
                else if (splittedPath[roomLevel + 1] === upload_down_map[1]) {
                    designSet.add(splittedPath[roomLevel - 1] + "_" + room)
                    designerFiles.push({ filename: file, room: room, roomType: splittedPath[roomLevel - 1], filekey: item })
                }
            }
        })
        for (let item of custSet) {
            if (!(designSet.has(item))) {
                designerFiles.push({ filename: "", room: item.split("_")[1], roomType: item.split("_")[0], filekey: "" })
            }
        }
        return { [upload_down_map[0]]: customerFiles, [upload_down_map[1]]: designerFiles }
    }

    const headerTemplate = (data, listType) => {
        return (
            <React.Fragment>
                <div className='flex align-items-center'>
                    <img src='/images/ihroom.svg' width='30' className='mr-3' />
                    <span className="image-text flex-grow-1 font-bold text-xl w-full mr-4 dijon-ivory py-2 px-3  gap-2 font-larken" style={{ borderRadius: "2rem" }} >{data.room}</span>
                    {listType == "designer" && <i className='pi pi-upload text-3xl cursor-pointer text-600 hover:text-900' onClick={(e) => handleUploadIconClick(e, data)} style={{ float: "right" }}></i>}
                </div>
            </React.Fragment>
        );
    }
    const handleUploadIconClick = (e, data) => {
        if (!room_file_type_master.current.roomType && !room_file_type_master.current.roomType) {
            const payload = { filter: { "usertype": "Designer" } }
            Promise.all([imagineHomeService.fetchMaster("nq-fileType-master", serviceId, payload), imagineHomeService.fetchMaster("nq-roomType-master", serviceId, {})]).then((res) => {
                if (res[0][0].fileTypes.length) room_file_type_master.current["fileType"] = res[0][0].fileTypes?.map(r => ({ name: r, value: r }))
                if (res[1][0].roomTypes.length) room_file_type_master.current["roomType"] = res[1][0].roomTypes?.map(f => ({ name: f, value: f }))
                setShowModal(true)
            })
        }
        else setShowModal(true)
        roomName.current = data.roomType + "_" + data.room

    }

    const onFileSelect = (e) => {
        setFile(null)
        if (e.files.length > 0) {
            setFile(e.files[0]);
        }
    };
    const handleSubmit = (e) => {
        setDisabled(true)
        let validateres = validateSubmit()
        if (validateres) {
            setErrMsg(validateres)
            setDisabled(false)
            return
        }
        const formData = new FormData()
        const rtype = roomName.current.split("_")[0]
        const rname = roomName.current.split("_")[1]
        formData.append("serviceId", serviceId)
        formData.append("customerID", selectedCustomer)
        formData.append("ProjectName", selectedProject)
        formData.append("roomType", rtype)
        formData.append("roomName", rname)
        formData.append("isCustomer", false)
        formData.append("fileType", selectedFileType)
        formData.append("file", file)

        const newRow = { filename: file.name, room: rname, roomType: rtype, filekey: "" }
        imagineHomeService.uploadRoomFile(formData).then((res) => {
            if (res) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: "File Upload Success" });
                roomWiseFiles[upload_down_map[1]].push(newRow)
                setSelectedFileType("")
                setFile(null)
                setShowModal(false)
                setDisabled(false)
            }
        })

    }
    const validateSubmit = () => {
        if (!selectedFileType) return "File Type Not Selected"
        if (!file) return "No File Chosen"
    }
    return (
        !loader ? <div className='' style={{ color: "#1D1B1C" }}>
            <Toast ref={toast} />
            <div className='col-12 flex flex-wrap'>
                <div className='col-12 lg:col-4'>
                    <label className='font-larken text-lg'>
                        Select Customer
                        <Dropdown value={selectedCustomer} options={customersList.current} onChange={(e) => setSelectedCustomer(e.value)} style={ddStyle} className="w-full mt-1" optionLabel="name" placeholder="Select Customer" />
                    </label>
                </div>
                <div className='col-12 lg:col-4'>
                    <label className='font-larken text-lg'>
                        Select Project
                        <Dropdown value={selectedProject} options={projectsList.current} onChange={(e) => setSelectedProject(e.value)} style={ddStyle} className="w-full mt-1" optionLabel="name" placeholder="Select Projects" />
                    </label>
                </div>
            </div>
            <div className='col-12 custom-tabview '>
                <TabView id="ih-tabview" className='' activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Customer Scan" style={{ color: "#1D1B1C" }} >
                        <ListFiles headerTemplate={headerTemplate} data={roomWiseFiles[upload_down_map[0]]} list="customer" />
                    </TabPanel>
                    <TabPanel header="Proposal Upload" style={{ color: "#1D1B1C" }}>
                        <ListFiles headerTemplate={headerTemplate} data={roomWiseFiles[upload_down_map[1]]} list="designer" />
                    </TabPanel>
                </TabView>
            </div>
            <Dialog showHeader={false} visible={showModal} modal style={{ width: "50vw", color: "#1D1B1C" }} onHide={() => setShowModal(false)}>
                <div className="col-12 pt-3 charcoal">
                    <div className='col-12'>
                        <label>
                            Select File Type
                            <Dropdown value={selectedFileType} options={room_file_type_master.current["fileType"] ?? []} onChange={(e) => setSelectedFileType(e.value)} className="bg-white w-full mt-1" optionLabel="name" placeholder="Select File Type" />
                        </label>
                    </div>
                    <div className='col-12'>
                        <FileUpload name="demo[]" maxFileSize={1000000} customUpload={true} auto={true} multiple={false} showUploadButton={false} onSelect={onFileSelect} onClear={() => setFile(null)}
                            mode="advanced" />
                    </div>
                    {errMsg && <p className='text-red-500'>{errMsg}</p>}
                    <div className='col-12 flex justify-content-between'>
                        <Button label='Submit' className='dijon-ivory font-Gerstner-ProgramRegular' disabled={disabled} onClick={handleSubmit}></Button>
                        <Button label='Cancel' className='p-button-danger font-Gerstner-ProgramRegular' onClick={() => setShowModal(false)}></Button>
                    </div>
                </div>


            </Dialog>
        </div> : <div style={{ width: "100%", textAlign: "center", height: "80vh", verticalAlign: "center", position: "relative" }}><i className="pi pi-spin pi-spinner" style={{ fontSize: '4rem', verticalAlign: "middle", position: "absolute", top: "45%", left: "45%" }}></i></div>
    )
}

export default DesignerHome