import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "jspdf-autotable";
import itemService from '../../service/itemService'
import { secondsToHm } from "../../utilities/utillFunction";
import { chartHeadingStyle, colorPalettes } from "../../utilities/constant";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import dbService from '../../service/dbService'
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";

const BatteriesChargeStatus = () => {
    const serviceID = localStorage.getItem("serviceId");
    const [data, setData] = useState(null)
    const [lineChart, setLineChart] = useState(null)
    const [selectedDate, setSelectedDate] = useState(new Date(moment().subtract(1, "days")))
    const [selectedSite, setSelectedSite] = useState(null)
    const [sites, setSites] = useState([])
    const maxDate = new Date()
    useEffect(async () => {
        let thingProjection = { thingName: 1, alias: 1, 'location.siteName': 1 }
        let things = await dbService.getThingNames(serviceID, thingProjection)
        things.forEach(t => {
            t.name = t.alias || t.location.siteName
            t.value = t.thingName
        })
        setSelectedSite(things[0].thingName)
        setSites(things)
    }, [])

    const getData = (thing, date) => {
        date = moment(date).format("YYYY-MM-DD")
        let payload = { filter: { thingName: thing, date: date } }
        let halfHrQuery = {
            'operation': 'aggregate', 'aggregate': [{ $match: { "thingName": thing, cur: { "$exists": true }, ts: { "$gte": date + " 00:00:00", "$lte": date + " 23:59:59" }, min: { "$mod": [30, 0] } } },
            { "$group": { "_id": { 'hr': "$hr", 'min': '$min' }, data: { $first: "$$ROOT" } } }, { "$sort": { 'data.ts': 1 } }]
        }

        Promise.all([itemService.getDayData(serviceID, payload), itemService.getAggregateDayData(serviceID, halfHrQuery)])
            .then((res) => {
                let daily = [{ row_title: "Duration (Hrs )", charging: 0, discharging: 0 },
                { row_title: "Capacity (AH)", charging:  0, discharging: 0 },
                { row_title: "Energy Consumed (KWH)", charging:  0, discharging: 0}]

                let dailyData = res[0]
                if (dailyData && dailyData.length) {
                    daily = [{ row_title: "Duration (Hrs )", charging: secondsToHm(dailyData[0].total_chrg_time), discharging: secondsToHm(dailyData[0].total_dischrg_time) },
                    { row_title: "Capacity (AH)", charging: dailyData[0].chrg_AH_for_day || 0, discharging: dailyData[0].dischrg_AH_for_day },
                    { row_title: "Energy Consumed (KWH)", charging: dailyData[0].chrg_kwh_for_day || 0, discharging: dailyData[0].dischrg_kwh_for_day }]

                }
                setData(daily)
                let halfHrdata = res[1]
                let linechart = getChartOPtion()
                if (halfHrdata && halfHrdata.length) {
                    let time = [], cur = []
                    halfHrdata.sort((a, b) => { return a.data.ts - b.data.ts })
                    halfHrdata.forEach(d => {
                        time.push(moment(d.data.ts, "YYYY-MM-DD hh:mm:ss").format("hh:mm A"))
                        cur.push(d.data.cur)
                    })
                    linechart.series[0].data = cur
                    linechart.xAxis.categories = time
                    setLineChart(linechart)
                }
            })
    }

    const getChartOPtion = () => {
        return {
            chart: {
                type: "spline",
                height: "400px",
                backgroundColor: "transparent",
            },
            title: {
                text: 'Charge Trend',
                style: chartHeadingStyle,
            },
            accessibility: {
                enabled: false,
            },
            tooltip: {
                valueDecimals: 2,
            },
            xAxis: {
                categories: [],
                labels: {
                    style: {
                        color: "#86969F",
                    },
                },
                title: {
                    text: "Time",
                },
            },
            yAxis: {
                stackLabels: {
                    enabled: false,
                },
                labels: {
                    style: {
                        color: colorPalettes.color2,
                    },
                },
                title: {
                    text: "Current",
                    style: {
                        color: colorPalettes.color2,
                    },
                },
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false,
                    },
                    pointStart: 0,
                    borderRadius: 5,
                    pointWidth: 9,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    name: "Current",
                    data: [],
                    color: colorPalettes.color2,
                    tooltip: {
                        valueSuffix: "",
                    },
                },
            ],
        }
    }
    const template = (options) => {
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";

        return (
            <div className={className} >
                <span className={titleClassName}>
                    <i className="pi-pw pi pi-filter"></i> Filter
                </span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    };

    const onSiteChange = (value) => {
        setSelectedSite(value)
    }

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value)
    }

    useEffect(() => {
        if (selectedDate && selectedSite) {
            getData(selectedSite, selectedDate)
        }
    }, [selectedDate, selectedSite])
    return (
        <>
            <Panel headerTemplate={template} toggleable="false" className="mb-2">
                <div className="grid">
                    <div className="col-12 lg:col-3 md:col-4">
                        <Dropdown value={selectedSite} options={sites} onChange={(e) => onSiteChange(e.target.value)} optionLabel="name" placeholder="Select Site" className="bg-white w-full" />
                    </div>
                    <div className="col-12 lg:col-3 md:col-4">
                        <Calendar className="w-full" value={selectedDate} maxDate={maxDate} onChange={(e) => handleDateChange(e)} showIcon readOnlyInput placeholder="Select Date" />
                    </div>
                </div>
            </Panel>
            {lineChart && lineChart.series[0].data.length > 0 ? <div className="col-12 card "> <HighchartsReact highcharts={Highcharts} options={lineChart} /> </div> : null}
            <div>
                <DataTable value={data} responsiveLayout="scroll" showGridlines>
                    <Column style={{ borderColor: "#f7f7f7", backgroundColor: "#f7f7f7" }} bodyClassName="color1-bg" header="" field='row_title'></Column>
                    <Column headerClassName="color1-bg" header="Charging" field='charging'></Column>
                    <Column headerClassName="color1-bg" header="Discharging(Battery On load)" field="discharging"></Column>
                </DataTable>
            </div>
        </>
    )
}

export default BatteriesChargeStatus;