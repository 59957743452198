import axios from "axios";
async function getNqItems() {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + localStorage.getItem("serviceId") + "/nq-items", { projection: { _id: 0, thingName: 1, serviceId: 1 } });
    return res.data;
}

async function getServiceDetails(serviceId) {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-serviceids", { filter: { serviceId: serviceId }, projection: { _id: 0, credentials: 0 } });
    return res.data;
}

async function getSensorMaster(query) {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-sensors", query);
    return res.data;
}

async function getAllowedImeis() {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + localStorage.getItem("serviceId") + "/nq-allowed-imeis", {});
    return res.data;
}

async function getStateWiseSites(payload) {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + localStorage.getItem("serviceId") + "/nq-site-addresses-stateWise", payload);
    return res.data;
}

async function getSitesLatLng(payload) {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + localStorage.getItem("serviceId") + "/nq-site-addresses", payload);
    return res.data;
}

async function getSiteDetails(query) {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + localStorage.getItem("serviceId") + "/nq-site-addresses", query);
    return res.data;
}

async function getSitesCities(payload) {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + localStorage.getItem("serviceId") + "/nq-site-addresses-cityWise", payload);
    return res.data;
}

async function getSitesZipcodes(payload) {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + localStorage.getItem("serviceId") + "/nq-site-addresses-zipCodeWise", payload);
    return res.data;
}

async function getLatLongForStates(states) {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + localStorage.getItem("serviceId") + "/nq-site-addresses-stateWise", { filter: { st: { $in: states } }, projection: { _id: 0, latlongs: 1 } });
    return res.data;
}

async function getSitesWithPutRequest(payload) {
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + localStorage.getItem("serviceId") + "/nq-site-addresses", payload, { operation: "count" });
    return res.data;
}

async function getConfigFileUploadStatus() {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + localStorage.getItem("serviceId") + "/nq-config-file-updates", {});
    return res.data;
}
async function getSitesAnalysis() {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + localStorage.getItem("serviceId") + "/nq-site-addresses-analysis", { projection: { _id: 0, s3filePath: 1, totalCount: 1, uploadingTime: 1 } });
    return res.data;
}

async function getWindzonePolygons(country) {
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + `/nextqore/nq-${country}-windzone-polygons`, { projection: { _id: 0, data: 1, name: 1, windSpeed: 1, zone: 1 } });
    return res.data;
}

async function getSignalType(serviceId, thingName) {
    const payload = {
        filter: {
            $and: [{ "sensorIdentification.itemId": thingName }, { "manufacturingParameters.make": "omnicomm" }],
        },
        projection: {
            "installationParameters.outputSignalType": 1,
        },
    };
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-config-sensors", payload);
    return res.data;
}

async function updateCalibration(serviceId, data) {
    const payload = {
        filter: { etlSequence: 4 },
        update: { $push: { etlReferenceData: data } },
    };
    const res = await axios.patch(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/etl-stagings", payload);
    return res.data;
}

async function getEtlStagings(serviceId) {
    const payload = { filter: { etlSequence: 4 }, projection: { etlReferenceData: 1 } };
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/etl-stagings", payload);
    return res.data;
}

async function getQuickSightId(serviceId) {
    const payload = { projection: { _id: 0 } };
    const res = await axios.post(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/quicksight-dashboard-ids`, payload);
    return res.data;
}

async function getTodaysExchangeRate() {
    let pipeline = [{ $sort: { date: -1 } }, { $limit: 1 }];
    const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-currency-exchange-rate", { operation: "aggregate", aggregate: pipeline });
    return res.data;
}

async function getSiteReport(serviceId) {
    const res = await axios.post(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-site-addresses-reports`, {});
    return res.data;
}

async function getSortedSiteReport(serviceId, uid) {
    const query = {
        operation: "aggregate",
        aggregate: [
            {
                $match: {
                    uid: uid,
                },
            },
            {
                $sort: {
                    ts: -1,
                },
            },
        ],
    };
    const res = await axios.put(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-site-addresses-reports`, query);
    return res.data;
}

async function getCitiesGropingState(serviceId, payload) {
    const res = await axios.put(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-site-addresses-cityWise`, payload);
    return res.data;
}

async function getZipsGroupingCity(serviceId, payload) {
    const res = await axios.put(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-site-addresses-zipCodeWise`, payload);
    return res.data;
}

async function getEventsData(serviceId) {
    let payload = {
        operation: "aggregate",
        aggregate: [
            {
                $sort: {
                    timestamp: -1,
                },
            },
            {
                $project: {
                    ts: 0,
                },
            },
        ],
    };
    let res = await axios.put(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-events`, payload);
    return res.data;
}

async function getEventComments(serviceId, userType, projectName) {
    const query = {
        filter: {
            projectName: projectName,
            // to: userType,
        },
        projection: {
            comment: 1,
            fileName: 1,
            fs_url: 1,
        },
    };
    const res = await axios.post(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-events`, query);
    return res.data;
}

async function eventUpdate(payload) {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_PARSER_URL}/fsupdateevent`, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
}

async function getProjectList(fsid) {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_PARSER_URL}/fslistprojects?fsid=${fsid}`, {});
        return res.data;
    } catch (error) {
        throw error;
    }
}

async function addProject(fsid, name) {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_PARSER_URL}/fscreateproject?project=${name}&fs_id=${fsid}`, {});
        return res.data;
    } catch (error) {
        throw error;
    }
}

async function getEventCount(serviceId) {
    const query = {
        operation: "aggregate",
        aggregate: [
            {
                $group: {
                    _id: "$to",
                    count: {
                        $sum: 1,
                    },
                },
            },
        ],
    };
    try {
        const res = await axios.put(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-events`, query);
        return res.data;
    } catch (error) {
        throw error;
    }
}

async function getStatusWiseEventCount(serviceId) {
    const query = {
        operation: "aggregate",
        aggregate: [
            {
                $group: {
                    _id: "$action",
                    count: {
                        $sum: 1,
                    },
                },
            },
        ],
    };
    try {
        const res = await axios.put(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/${serviceId}/nq-events`, query);
        return res.data;
    } catch (error) {
        throw error;
    }
}

async function getUserPermissionInfo(serviceID, username ){
    const query = {
        filter :{
            serviceId: serviceID, $or :[{"user.mobile": username},{"user.email": username}]
        }
    }
    const res = await axios.post(`${process.env.REACT_APP_DOCDB_SERVICE_URL}/nextqore/nq-userpermissions`, query);
    return res.data;
}

async function updateAlias (serviceId,thingName,data){
    let serviceBlock = JSON.parse(localStorage.getItem("service")).serviceBlock;
    let field;
    if(serviceBlock==="item"){
        field="thingName"
    }
    else{
        field=serviceBlock+"Name"
    }
    const payload = {
        filter: {[field]:thingName },
        update:  {$set:{"alias":data}},
    };
   serviceBlock="/nq-"+serviceBlock+"s";
    const res = await axios.patch(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + serviceBlock, payload);
    return res.data; 
}

async function getCountryISOCodes(){
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-country-codes",{});
    return res.data;
}

async function getHistoricalWeatherData(serviceId, payload){
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/"+ serviceId + "/nq-sites-weather-history", payload);
    return res.data;
}

async function getThingNames(serviceID, projection) {
    const doc_db_url = process.env.REACT_APP_DOCDB_SERVICE_URL;
    let states = JSON.parse(localStorage.getItem("states")) || [];
    let cities = JSON.parse(localStorage.getItem("cities")) || [];
    let zipcodes = JSON.parse(localStorage.getItem("zipcodes")) || [];
    let serviceBlock = JSON.parse(localStorage.getItem("service")).serviceBlock;
    let field = serviceBlock + "Name"
    let payload = { filter: {}, projection: { make: 1, model: 1, size: 1, [field]: 1, alias: 1 } };
    if(projection && Object.keys(projection).length) payload.projection = projection
    if (serviceBlock === "space") {
        payload = {
            filter: {}
        }
    }
    let queryArray = [];
    if ((localStorage.getItem("states") && JSON.parse(localStorage.getItem("states")).length>0) || (localStorage.getItem("cities")&& JSON.parse(localStorage.getItem("cities")).length>0) ||(localStorage.getItem("zipcodes")&& JSON.parse(localStorage.getItem("zipcodes")).length>0)) {
        if (zipcodes.length > 0) {
            queryArray.push({ "location.zip": { $in: zipcodes } });
        }
        else if (cities.length > 0) {
            queryArray.push({ "location.city": { $in: cities } });
        }
        else {
            queryArray.push({ "location.state": { $in: states } });
        }
    }
    if (queryArray.length > 0) {
        payload.filter = {
            $and: [...queryArray],
        };
    } else {
        payload.filter = {
            $and: [{}],
        };
    }
    const res = await axios.post(doc_db_url + "/" + serviceID + `/nq-${serviceBlock}s`, payload);
    return res.data;

}

async function getGatewayData(payload){
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-gateways", payload);
    return res.data; 
}

async function getInputFormat(payload){
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-input-format-masters", payload);
    return res.data; 
}
async function getInputType(payload){
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-input-type-masters", payload);
    return res.data; 
}
async function getDBAllowedKeys(payload){
    const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-service-types", payload);
    return res.data; 
}
async function addInputFormat(payload){
    const headers = {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${localStorage.getItem('token')}`
    }
    const res = await axios.post(process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL + "/add-input-format", payload, {headers: headers});
    return res.data; 
}

async function deleteMyAccount(payload){
    const headers = {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${localStorage.getItem('token')}`
    }
    const res = await axios.post(process.env.REACT_APP_TENANT_SERVICE_URL+"/delete-account", payload, headers)
    return res.data
}

const service = {
    getNqItems,
    getServiceDetails,
    getSensorMaster,
    getAllowedImeis,
    getStateWiseSites,
    getSitesLatLng,
    getSiteDetails,
    getSitesCities,
    getSitesZipcodes,
    getLatLongForStates,
    getSitesWithPutRequest,
    getConfigFileUploadStatus,
    getSitesAnalysis,
    getWindzonePolygons,
    getSignalType,
    updateCalibration,
    getEtlStagings,
    getQuickSightId,
    getTodaysExchangeRate,
    getSiteReport,
    getCitiesGropingState,
    getZipsGroupingCity,
    getSortedSiteReport,
    getEventsData,
    getProjectList,
    addProject,
    getEventComments,
    eventUpdate,
    getEventCount,
    getStatusWiseEventCount,
    getUserPermissionInfo,
    updateAlias,
    getCountryISOCodes,
    getHistoricalWeatherData,
    getThingNames,
    getInputType,
    getInputFormat,
    getGatewayData,
    getDBAllowedKeys,
    addInputFormat,
    deleteMyAccount,
}

export default service;
